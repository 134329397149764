import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import nail from "../../Assets/Projects/nail.png";
import emotion from "../../Assets/Projects/emotion.png";
import road from "../../Assets/Projects/road.png";
import chatify from "../../Assets/Projects/chatify.png";
import meme from "../../Assets/Projects/meme.png";
import bitsOfCode from "../../Assets/Projects/investoxx.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Chatify"
              description="Personal Chat Room or Workspace to share resources and hangout with friends build with react.js, Material-UI, and Firebase. Have features which allows user for realtime messaging, image sharing as well as supports reactions on messages."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Investoxx"
              description="Unlock the future of investing with accurate stock market forecasts. Investoxx is a investment platform that leverages advanced technology and analytics to provide all users with reliable stock predictions and expert market analysis"
              ghLink="https://github.com/karankewat02/Investoxx"
              demoLink="https://www.investoxx.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={road}
              isBlog={false}
              title="Road-ways"
              description="Develop a drone that will fly over roads, collecting data like potholes, street lamps, heavy traffic etc. This data collected will further be given to our software solution that with analyze the image collected and make it in a visual format. This data can be further used by the local gov authorizes. Techstack- Python , tensorFlow etc."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nail}
              isBlog={false}
              title="The Nail shop"
              description="Used react native to develop a cross platform application for shopping application where you can find products that are related to nail shop and can be used for nails . Used firebase for users authentication and server communication."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              // demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={meme}
              isBlog={false}
              title="Meme Generator"
              description="I utilized the canvas constructor and jokes API to create a random meme. The system then opens the camera, and the person interacting with it is prompted to react in accordance with the meme, thereby generating their own personalized meme. "
              // ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person."
              ghLink="https://github.com/soumyajit4419/Face_And_Emotion_Detection"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
