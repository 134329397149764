// import React from 'react'
// 
// export default function Contact() {
//   return (
//     <div className='contact-page' >
//       <Particle />
//         <h1>Contact Me</h1>
//         <form className='contact-form' action='' method='POST'>
//           <input type='text' placeholder='Name' /> <br/>
//           <input type='email' placeholder='Email'/><br/>
//           <textarea placeholder='Message' /><br/>
//           <button>Send</button>
//         </form>
//     </div>
//   )
// }
import React from 'react'
import Particle from '../Particle'
import './Contact.css'
import { AiFillFacebook, AiFillInstagram, AiFillLinkedin, AiFillMail } from 'react-icons/ai'

function Contact() {
  return (
    <section className='c-contact'>
      <Particle />
        {/* <div className='content'>
            <h1>Contact Us</h1>
            <p>contact us or give us feed back about your views or suggestion or any queries</p>
        </div> */}

        <div className='c-container'>
            <div className='contactInfo'>
                <div className='box'>
                    <div className='icon'onClick={()=> window.open("https://www.linkedin.com/in/juhinema11", "_blank")}><AiFillLinkedin/></div>
                    <div className='text'>
                        <h3>LinkedIn</h3>
                        <p>Get in touch on LinkedIn</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='icon' onClick={()=> window.open("https://instagram.com/juhinema_?igshid=YmMyMTA2M2Y=", "_blank")}><AiFillInstagram/></div>
                    <div className='text'>
                        <h3>Instagram</h3>
                        <p>Get in touch on Instagram</p>
                    </div>
                </div>
                <div className='box'>
                    <div className='icon'onClick={()=> window.open("mailto:juhinema200@gmail.com", "_blank")}><AiFillMail/></div>
                    <div className='text'>
                        <h3> Mail</h3>
                        <p>Get in touch on mail</p>
                    </div>
                </div>
            </div>
            <div className='contactForm'>
                <form method='POST' action='https://formspree.io/f/xleknrrk'>

                    <h2>Send Message</h2>
                    <div className='inputBox'>
                        <input type="text" name='Name' required="required"/>
                        <span>Full name</span>
                    </div>
                    <div className='inputBox'>
                        <input type="email" name='Email' required="required"/>
                        <span>Email</span>
                    </div>
                    <div className='inputBox'>
                        <textarea name='Description' required="required"></textarea>
                        <span>Send your message</span>
                    </div>
                    <div className='inputBox'>
                        <input type="submit" name='Submit' value='send'/> 
                    </div>  
                </form>
            </div>
        </div>
    </section>
  )
}

export default Contact